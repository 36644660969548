<template>

  <main>
    <div class="container">
      <h1>That page doesn't exist...</h1>
      <router-link to="/dashboard" class="button" v-if="$store.getters['user/isAuthenticated']">Go to Dashboard</router-link>
      <router-link to="/login" class="button" v-else>Go to Login</router-link>
    </div>
  </main>

</template>

<script>

  export default {}

</script>

<style lang="stylus" scoped>

  main
    @apply flex
    @apply h-screen

    @apply bg-top
    @apply bg-cover
    @apply bg-scroll

    background-image: url('~@/assets/images/404-mobile.jpg')

    +breakpoint(sm)
      @apply bg-bottom
      background-image: url('~@/assets/images/404.jpg')

  .container
    @apply p-4
    @apply pb-8
    @apply mx-auto
    @apply text-center

    @apply flex
    @apply flex-col
    @apply justify-end
    @apply items-center

    +breakpoint(sm)
      @apply pb-16

      max-width: 48rem

  h1
    @apply mb-12
    @apply text-white

</style>
